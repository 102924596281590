<template>
  <div>
    <table
      class="table is-fullwidth is-striped is-narrow is-size-7 has-margin-top has-margin-bottom"
    >
      <thead>
        <tr>
          <th>
            {{ $t("Components.Dashboards.ReservationYearStats.Table_Year") }}
          </th>
          <th align="right">
            {{
              $t("Components.Dashboards.ReservationYearStats.Table_Nr-of-res")
            }}
          </th>
          <th align="right">
            {{
              $t("Components.Dashboards.ReservationYearStats.Table_Total-seats")
            }}
          </th>
          <th align="right">
            {{
              $t(
                "Components.Dashboards.ReservationYearStats.Table_Total-revenue"
              )
            }}
          </th>
          <th align="right">
            {{
              $t("Components.Dashboards.ReservationYearStats.Table_Churn-rate")
            }}
          </th>
        </tr>
      </thead>
      <tbody name="slide-down" is="transition-group">
        <tr v-for="stat in filteredStats" :key="stat.Year">
          <td>{{ stat.Year }}</td>
          <td align="right">
            {{ stat.NrOfReservations === -1 ? "--" : stat.NrOfReservations }}
          </td>
          <td align="right">
            {{ stat.TotalSeats === -1 ? "--" : stat.TotalSeats }}
          </td>
          <td align="right">
            {{
              stat.TotalRevenueExclTax === -1
                ? "--"
                : stat.TotalRevenueExclTax | toCurrency
            }}
          </td>
          <td align="right">
            {{ stat.ChurnRate === -1 ? "--" : stat.ChurnRate + "%" }}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="5">
            <div></div>

            <div
              v-if="!showAllYears && reservationYearStats.length > 3"
              class="has-text-centered"
            >
              <a class="button is-small" @click="setShowAll">
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'chevron-down']" />
                </span>
                <span>{{
                  $t(
                    "Components.Dashboards.ReservationYearStats.Button_Show-more"
                  )
                }}</span>
              </a>
            </div>

            <div v-if="showAllYears" class="has-text-centered">
              <a class="button is-small" @click="setShowAll">
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'chevron-up']" />
                </span>
                <span>{{
                  $t(
                    "Components.Dashboards.ReservationYearStats.Button_Show-less"
                  )
                }}</span>
              </a>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import statsProvider from "@/providers/stats";

export default {
  props: {
    companyId: {
      default: 0,
      type: Number
    },

    locationId: {
      default: 0,
      type: Number
    }
  },

  data() {
    return {
      reservationYearStats: [
        {
          Year: new Date().getFullYear() + 1,
          NrOfReservations: -1,
          TotalSeats: -1,
          TotalRevenueExclTax: -1,
          ChurnRate: -1
        },
        {
          Year: new Date().getFullYear(),
          NrOfReservations: -1,
          TotalSeats: -1,
          TotalRevenueExclTax: -1,
          ChurnRate: -1
        },
        {
          Year: new Date().getFullYear() - 1,
          NrOfReservations: -1,
          TotalSeats: -1,
          TotalRevenueExclTax: -1,
          ChurnRate: -1
        }
      ],
      showAllYears: false
    };
  },

  computed: {
    filteredStats() {
      let stats = [];

      if (this.showAllYears) {
        stats = this.reservationYearStats;
      } else {
        stats = this.reservationYearStats.slice(0, 3);
      }

      return stats;
    }
  },

  created() {
    this.getLocationReservationYearStats();
  },

  methods: {
    getLocationReservationYearStats() {
      statsProvider.methods
        .getLocationReservationYearStats(this.locationId, this.companyId)
        .then(response => {
          if (response.status === 200) {
            this.reservationYearStats = response.data;
          }
        });
    },

    setShowAll() {
      this.showAllYears = !this.showAllYears;
    }
  }
};
</script>
